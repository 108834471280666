<template>
  <CRow>
    <CModal
        :title="$lang.common.general.reason"
        size="lg"
        :show.sync="blockModal"
        :no-close-on-backdrop="true"
        color="primary"
    >
      <CCardBody>
        <CRow>
          <CCol md="12">
            <c-textarea
                v-model="poll.reason"
                :rows="5"
                :cols="10"
                placeholder="Add Reason"
            ></c-textarea>
          </CCol>
          <CCol md="6">
            <div class="form-group">
              <CButton
                  color="primary"
                  v-c-tooltip="$lang.poll.crud.poll_status"
                  :disabled="submitted"
                  v-on:click="blockPoll(statusId)"
              >
                Submit
              </CButton>
            </div>
          </CCol>
        </CRow>
      </CCardBody>
      <template #footer style="display: none">
        <CButton
            @click="darkModal = false"
            color="danger"
            style="display: none"
        >Discard
        </CButton
        >
        <CButton
            @click="darkModal = false"
            color="success"
            style="display: none"
        >Accept
        </CButton
        >
      </template>
    </CModal>

    <CModal
        title="View Report"
        size="lg"
        :show.sync="largeModal"
        :no-close-on-backdrop="true"
        :hide-footer="true"
        color="primary"
    >
      <CCardBody>
        <CCol md="12" class="align-self-center">
          <CRow>
            <CCol sm="6" md="4" lg="12">
              <p class="custome-labal">
                Reason :
              </p>
              <p class="custome-detail">
                {{ self.report.reason }}
              </p>
            </CCol>
          </CRow>
        </CCol>
      </CCardBody>
    </CModal>
    <CCol col="12">
      <CCard>
        <CCardHeader>
          <back-to-list></back-to-list>
          <div class="row">
            <CCol sm="12" class="align-self-center">
              <div class="text-sm-right mt-3 mt-sm-0">
                <CButtonGroup class="file-export">
                  <CButton color="secondary" disabled>
                    {{ $lang.buttons.export.title }}
                  </CButton>
                  <CButton
                      color="danger"
                      v-c-tooltip="$lang.buttons.export.pdf"
                      v-on:click="exports(self, 'pdf', module, 'Poll Report',self.pollId)"
                  ><i class="fas fa-file-pdf"></i></CButton>
                  <VueJsonToCsv
                      class="btn btn-success"
                      v-c-tooltip="$lang.buttons.export.excel"
                      :json-data="json_data"
                      :labels="json_label"
                      :csv-title="json_title"
                  >
                    <i class="fas fa-file-excel"></i>
                  </VueJsonToCsv>
                </CButtonGroup>
              </div>
            </CCol>
          </div>
        </CCardHeader>
        <CCardBody>
          <div class="row">
            <CCol sm="11" class="align-self-center">
              <strong>Poll : {{ self.pollName }} </strong>
            </CCol>
            <CCol sm="1" v-if="!poll.blockedReason">
              <CButton
                  :color="statusColor"
                  v-c-tooltip="statusLabel"
                  v-on:click="beforeBlocked()">
                {{ statusLabel }}
              </CButton>
            </CCol>
            <CCol sm="6" v-show="poll.blockedReason">
              <strong>Blocked Reason: </strong> {{ poll.blockedReason }}
            </CCol>
          </div>
          <br>
          <v-server-table
              :columns="columns"
              :url="listUrl"
              :options="options"
              ref="myTable"
              @loaded="exports(self, 'Excel', module, 'Poll Report', self.pollId)">
            <template #description="data">
              <span :title="data.row.description">
                {{ trimfunction(data.row.description, 50) }}
              </span>
            </template>
            <template #actions="data">
              <CButton color="primary" v-on:click="viewReport(data.row.id)">
                <i class="fas fa-eye"></i>
              </CButton>
            </template>
          </v-server-table>
        </CCardBody>
      </CCard>
    </CCol>
  </CRow>
</template>
<script>
import Vue from "vue";
import VueForm from "vue-form";
import store from "/src/store/store.js";
import {gAC} from "/src/store/url.js";
import {Mixin} from "/src/mixins/index.js";
import options from "/src/validations/validations.js";
import VueNotifications from "vue-notifications";
import VueToasted from "vue-toasted";
import {ServerTable} from "vue-tables-2";
import VueSweetalert2 from "vue-sweetalert2";
import VueJsonToCsv from "vue-json-to-csv";
import BackToList from "../../backToList";

function toast({title, message, type, timeout, cb}) {
  if (type === VueNotifications.types.warn) type = "show";
  return Vue.toasted[type](message, {
    duration: timeout,
    position: "top-left",
  });
}

Vue.use(VueSweetalert2);
Vue.use(ServerTable, {}, false);
Vue.use(VueForm, options);

Vue.use(VueToasted);
const optionss = {
  success: toast,
  error: toast,
  info: toast,
  warn: toast,
  position: "top-center",
};
Vue.use(VueNotifications, optionss);
Vue.use(VueForm, options);
export default {
  name: "PollReports",
  mixins: [Mixin],
  components: {
    VueJsonToCsv,
    BackToList,
  },
  data() {
    return {
      pollId: '',
      statusId: "",
      submitted: false,
      statusLabel: "Block",
      statusColor: "danger",
      pollName: '',
      listUrl: '/users/groups/poll/report/list',
      statusOption: [],
      json_data: [],
      json_label: {},
      json_title: "",
      activePage: 1,
      largeModal: false,
      blockModal: false,
      self: this,
      module: gAC,
      report: {
        reason: '',
      },
      poll: {
        reason: '',
        blockedReason: '',
        statusId: "",
        isStatus: "",
        isActive: "",
      },
      columns: ["description", "username", "actions"],
      data: [],
      options: {
        headings: {
          reason: this.$lang.post_report.table.reason,
          username: this.$lang.post_report.table.username,
          actions: this.$lang.common.general.actions,
        },
        editableColumns: ["name", "isVerified"],
        sortable: ["name", "isVerified"],
        filterable: ["name", "isVerified"],
      },
    };
  },
  created() {
    let self = this;
    self.pollId = this.$route.params.poll_id;
    if (this.$route.params.poll_id) {
      self.listUrl = self.listUrl + "/" + this.$route.params.poll_id;
    }
    axios.get('users/groups/poll/detail/' + this.$route.params.poll_id).then((response) => {
      if (response) {
        let responseData = response.data.data;
        self.poll.isStatus = responseData.isActiveStatus ?? '';
        self.poll.blockedReason = responseData.blockedReason ?? '';
        self.poll.isActive = responseData.isActive ?? '';

        if (self.poll.isStatus === "Active") {
          self.poll.isStatus = 'Open';
          self.statusLabel = this.getStatusLabel(2);
          self.statusId = "2";
          self.statusColor = "danger";
        } else {
          self.statusId = "1";
          self.statusLabel = this.getStatusLabel(1);
          self.statusColor = "success";
        }
      } else {
        self.data = [];
      }
    });

    self.pollName = localStorage.getItem('pollName');
    store.commit('showLoader', false); // Loader Start
  },
  mounted() {
    let self = this;
    self.statusOption.push(
        {value: "1", label: "Active"},
        {value: "0", label: "InActive"},
        {value: "2", label: "Block"}
    );
    store.commit('showLoader', false); // Loader Start
  },
  watch: {
    $route: {
      immediate: true,
      handler(route) {
        if (route.query && route.query.page) {
          this.activePage = Number(route.query.page);
        }
      },
    },
  },
  methods: {
    getStatusLabel(getStatus) {
      let valObj = " ";
      valObj = this.statusOption.filter(function (elem) {
        if (elem.value == getStatus) return elem.value;
      });
      if (valObj.length > 0) return valObj[0].label;
    },
    beforeBlocked() {
      let self = this;
      self.poll.reason = "";
      if (self.poll.isActive === 1) {
        this.blockModal = true;
      } else {
        this.blockPoll(self.statusId);
      }
    },
    viewReport(id) {
      let self = this;
      axios.get('users/groups/poll/report/view/' + id).then((response) => {
        if (response) {
          let responseData = response.data.data;
          self.report.reason = responseData.description;
        } else {
          self.data = [];
        }
      });
      self.largeModal = true;
    },
    blockPoll(statusId) {
      let self = this;
      self.submitted = true; //Enable Button
      if (self.poll.isActive === 1) {
        if (self.poll.reason === "") {
          self.submitted = false; //Enable Button
          return false;
        }
        this.$swal({
          title: this.$lang.common.status.want_to_block,
          type: "warning",
          showCancelButton: true,
          confirmButtonColor: "#e55353",
          confirmButtonText: this.$lang.common.status.yes_block,
          cancelButtonText: this.$lang.common.status.cancel,
        }).then((result) => {
          if (result.value) {
            const id = this.$route.params.poll_id;

            setTimeout(() => {
              let self = this;
              const postData = {
                status: statusId,
                reason: self.poll.reason,
              };
              let status, button;
              if (self.statusId === "1") {
                self.statusId = "2";
                self.statusColor = "danger";
                status = this.getStatusLabel(1);
                button = "Block";
              } else {
                self.statusId = "1";
                self.statusColor = "success";
                status = "Blocked";
                button = this.getStatusLabel(1);
              }

              axios
                  .post('users/groups/poll/status/' + id, postData)
                  .then(function (response) {
                    if (response.data.code === 200) {
                      self.poll.isStatus = status;
                      self.statusLabel = button;
                      self.blockModal = false;
                      self.submitted = false; //Enable Button
                      self.$router.go();
                    } else {
                      self.err_msg = response.data.message;
                      self.dismissCountDown = 10;
                    }
                  })
                  .catch(function (error) {
                    self.submitted = false; //Enable Button
                  });
            }, 500);
          }
        });
      } else {
        let self = this;
        const id = this.$route.params.poll_id;
        const postData = {
          status: statusId,
          reason: null,
        };
        let status, button;
        if (self.statusId === "1") {
          self.statusId = "2";
          self.statusColor = "danger";
          status = this.getStatusLabel(1);
          button = "Block";
        } else {
          self.statusId = "1";
          self.statusColor = "success";
          status = "Blocked";
          button = this.getStatusLabel(1);
        }
        axios
            .post('users/groups/poll/status/' + id, postData)
            .then(function (response) {
              if (response.data.code === 200) {
                self.poll.isStatus = status;
                self.statusLabel = button;
                self.blockModal = false;
                self.submitted = false; //Enable Button
                self.$router.go();
              } else {
                self.err_msg = response.data.message;
                self.dismissCountDown = 10;
              }
            })
            .catch(function (error) {
              self.submitted = false; //Enable Button
            });
      }
    },
  },
};
</script>
<style>
div .error {
  color: red;
}

.hasError label {
  color: red;
}
</style>
